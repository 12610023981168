export const sortedForConsultation = (data) => {
  if (!data) {
    return [];
  }
  if (data.length < 2) {
    return data;
  }
  let filteredArrayEnd = data.filter(function (obj) {
    return obj.status === "end";
  });

  filteredArrayEnd.sort(function (a, b) {
    const dateA = new Date(a.startAt);
    const dateB = new Date(b.startAt);

    return dateB - dateA;
  });

  let filteredArrayInProgress = data.filter(function (obj) {
    return obj.status === "in_progress";
  });

  filteredArrayInProgress.sort(function (a, b) {
    const dateA = new Date(a.createAt);
    const dateB = new Date(b.createAt);

    return dateB - dateA;
  });

  const result = filteredArrayInProgress.concat(filteredArrayEnd);
  return result;
};
