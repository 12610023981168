import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { getManagers, newManager } from "../http/managerAPI";
import { toast } from "react-toastify";
import styles from "./styles/Managers.module.css";
import ManagerRow from "../components/ManagerRow";
import { Modal } from "react-bootstrap";
import { useAuth } from "../AuthContext";
import RcPagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import LoaderComponent from "../components/LoaderComponent";

const Consultation = ({ socket, setIsPlay, setIsMessageSoundPlay }) => {
  const { chatId } = useAuth();

  const [showPassword, setShowPassword] = useState(false);
  const [consultations, setConsultations] = useState([]);
  const [name, setName] = useState("");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const usersPerPage = 10;

  useEffect(() => {
    const fetchConsultations = async () => {
      try {
        const users = await getManagers();

        setConsultations(users);
      } catch (error) {}
    };
    fetchConsultations();
  }, []);

  const handleNotificationClick = () => {
    setIsPlay(0);
  };

  useEffect(() => {
    socket.on("message", async (newMessage) => {
      const storedChatIds = JSON.parse(localStorage.getItem("chatIds")) || [];
      if (
        storedChatIds.includes(newMessage.channelId) &&
        newMessage.type === "text" &&
        chatId !== newMessage.user.id
      ) {
        const truncatedText =
          newMessage.text.length > 50
            ? `${newMessage.text.slice(0, 50)}...`
            : newMessage.text;
        toast.info(
          `Нове повідомлення\n${newMessage.user.firstName}:\n ${truncatedText}`
        );
        setIsMessageSoundPlay((prev) => (prev += 1));
      }
    });
    socket.on("newConsultation", () => {
      toast.info("Зявився новий запит", {
        onClick: handleNotificationClick,
        autoClose: 9000,
      });
      setIsPlay((prev) => (prev += 1));
    });
    return () => {
      socket.off("newConsultation");
      socket.off("message");
    };
  }, [socket]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name && login && password) {
      try {
        const manager = await newManager(name, login, password);

        if (manager) {
          setShowModal(false);
          setName("");
          setLogin("");
          setPassword("");
          const managers = await getManagers();
          setConsultations(managers);
          toast.success("Менеджера створено успішно");
        }
      } catch (error) {
        toast.error("Щось пішло не так, спробуйте ще раз");
      }
    } else {
      toast.error("Заповніть усі поля");
    }
  };

  const indexOfLastUser = (currentPage + 1) * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  if (consultations.length === 0) {
    return <LoaderComponent />;
  }

  return (
    <>
      <div className={styles.userTable}>
        <Table page-size="4">
          <thead>
            <tr style={{ fontWeight: "600" }}>
              <th style={{ width: "14vw" }}>Ім'я</th>
              <th style={{ width: "14vw" }}>Юзернейм</th>
            </tr>
          </thead>

          <tbody>
            {consultations

              .slice(indexOfFirstUser, indexOfLastUser)
              .map((user) => (
                <ManagerRow key={user.id} user={user} />
              ))}
          </tbody>
        </Table>
      </div>
      <button
        className={styles.newManagerBtn}
        onClick={() => setShowModal(true)}
      >
        Новий менеджер
      </button>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        centered
        className={styles.backdrop}
      >
        <Modal.Body className={styles.modal}>
          <h1 className={styles.newManagerTitle}>Новий менеджер</h1>
          <input
            className={styles.managersInput}
            type="text"
            placeholder="Ім'я"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            className={styles.managersInput}
            type="text"
            placeholder="Логін"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
          />
          <div className={styles.passwordInputContainer}>
            <input
              className={`${styles.passwordInput} ${styles.managersInput}`}
              type={showPassword ? "text" : "password"}
              placeholder="Пароль"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              className={styles.showPasswordButton}
              onClick={() => setShowPassword(!showPassword)}
            >
              <img
                src={
                  showPassword ? "/loginImg/eye.slash.svg" : "/loginImg/eye.svg"
                }
                alt={showPassword ? "Скрити пароль" : "Показати пароль"}
                className={styles.loginSvg}
              />
            </button>
          </div>
          <div className={styles.btnDiv}>
            <button className={styles.managersBtn} onClick={handleSubmit}>
              Створити
            </button>
            <button
              className={styles.closeBtn}
              onClick={() => setShowModal(false)}
            >
              Закрити
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {consultations.length > usersPerPage && (
        <div className={styles.paginationContainer}>
          {consultations.length > usersPerPage && (
            <RcPagination
              current={currentPage + 1}
              total={consultations.length}
              pageSize={usersPerPage}
              onChange={(page) => setCurrentPage(page - 1)}
              className={styles.paginationContainer}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Consultation;
