export const sortedForActual = (data) => {
  if (!data) {
    return [];
  }
  if (data.length < 2) {
    return data;
  }

  return data.sort(function (a, b) {
    const dateA = new Date(a.createAt);
    const dateB = new Date(b.createAt);

    return dateA - dateB;
  });
};
