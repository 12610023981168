import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { getConsultations } from "../http/custumersAPI";
import ConsultationsRow from "../components/ConsultationsRow";
import styles from "./styles/Consultations.module.css";
import { sortedForConsultation } from "../utils/sortedForConsultation";
import { toast } from "react-toastify";

import RcPagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { useAuth } from "../AuthContext";
import LoaderComponent from "../components/LoaderComponent";

const Consultation = ({ socket, setIsPlay, setIsMessageSoundPlay }) => {
  const { chatId } = useAuth();

  const [consultations, setConsultations] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const usersPerPage = 10;

  const handleNotificationClick = () => {
    setIsPlay(0);
  };

  useEffect(() => {
    socket.on("message", async (newMessage) => {
      const storedChatIds = JSON.parse(localStorage.getItem("chatIds")) || [];
      if (
        storedChatIds.includes(newMessage.channelId) &&
        newMessage.type === "text" &&
        chatId !== newMessage.user.id
      ) {
        const truncatedText =
          newMessage.text.length > 50
            ? `${newMessage.text.slice(0, 50)}...`
            : newMessage.text;
        toast.info(
          `Нове повідомлення\n${newMessage.user.firstName}:\n ${truncatedText}`
        );
        setIsMessageSoundPlay((prev) => (prev += 1));
      }
    });
    socket.on("newConsultation", () => {
      toast.info("Зявився новий запит", {
        onClick: handleNotificationClick,
        autoClose: 9000,
      });
      setIsPlay((prev) => (prev += 1));

      const fetchConsultations = async () => {
        try {
          const users = await getConsultations();
          const sorted = sortedForConsultation(users);
          setConsultations(sorted);
        } catch (error) {}
      };
      fetchConsultations();
    });
    return () => {
      socket.off("newConsultation");
      socket.off("message");
    };
  }, [socket]);

  useEffect(() => {
    const fetchConsultations = async () => {
      try {
        const users = await getConsultations();
        const sorted = sortedForConsultation(users);
        setConsultations(sorted);
      } catch (error) {}
    };
    fetchConsultations();
  }, []);

  const indexOfLastUser = (currentPage + 1) * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  if (consultations.length === 0) {
    return <LoaderComponent />;
  }

  return (
    <>
      <div className={styles.userTable}>
        <Table page-size="4">
          <thead>
            <tr style={{ fontWeight: "600" }}>
              <th style={{ width: "14vw" }}>Ім'я</th>
              <th style={{ width: "14vw" }}>Юзернейм</th>
              <th style={{ width: "14vw" }}>Вік</th>
              <th style={{ width: "14vw" }}>Мессенджер</th>
              <th style={{ width: "14vw" }}>Час звернення</th>
              <th style={{ width: "14vw" }}>Статус</th>
              <th style={{ width: "7vw" }}>Дії</th>
            </tr>
          </thead>

          <tbody>
            {consultations
              .slice(indexOfFirstUser, indexOfLastUser)
              .map((user) => (
                <ConsultationsRow
                  key={user.id}
                  user={user.user}
                  consultation={user}
                />
              ))}
          </tbody>
        </Table>
      </div>
      {consultations.length > usersPerPage && (
        <RcPagination
          current={currentPage + 1}
          total={consultations.length}
          pageSize={usersPerPage}
          onChange={(page) => setCurrentPage(page - 1)}
          className={styles.paginationContainer}
        />
      )}
    </>
  );
};

export default Consultation;
