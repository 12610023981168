export const getType = (file, format) => {
  const type = file.split("/")[0];

  if (type === "application") {
    switch (format) {
      case "oga":
      case "ogg":
      case "wav":
      case "mp3":
        return "audio";
      case "mp4":
      case "webm":
      case "MOV":
      case "AVI":
      case "WMV":
      case "move":
        return "video";
      case "jpg":
      case "jpeg":
      case "png":
        return "image";
      default:
        return type;
    }
  } else {
    return type;
  }
};
