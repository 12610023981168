export const getDay = (timeFromBackend) => {
  const backendDate = new Date(timeFromBackend);

  const months = [
    "січня",
    "лютого",
    "березня",
    "квітня",
    "травня",
    "червня",
    "липня",
    "серпня",
    "вересня",
    "жовтня",
    "листопада",
    "грудня",
  ];

  const dayOfMonth = backendDate.getDate();
  const monthName = months[backendDate.getMonth()];
  return `${dayOfMonth} ${monthName} ${backendDate.getFullYear()}`;
};
