import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const UsersCount = ({ data }) => {
  const chartData = {
    labels: data.months,
    datasets: [
      {
        label: "Унікальні користувачі",
        data: data.unicalUsersCount,
      },
      {
        label: "Користувачі",
        data: data.usersCount,
      },
      {
        label: "Консультації",
        data: data.consultationsCount,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      <h2>Унікальні консультації</h2>{" "}
      <Line data={chartData} options={options} />
    </>
  );
};

export default UsersCount;
