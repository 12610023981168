import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const HowFindUs = ({ data }) => {
  const datasets = data.howFindUs.map((i) => {
    return {
      label: i.text,
      data: i.count,
      key: i.text,
    };
  });

  const chartData = {
    labels: data.months,
    datasets: datasets,
  };
  const options = {
    scales: {
      x: {
        type: "category",
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      <h2>Як знайшли нас?</h2> <Line data={chartData} options={options} />
    </>
  );
};

export default HowFindUs;
