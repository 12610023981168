import { $authHost } from "./index";
import { toast } from "react-toastify";

export const getMessages = async (channelId) => {
  try {
    const { data } = await $authHost.get(`${channelId}/messages`);
    return data;
  } catch (e) {
    console.log(e);
    return [];
  }
};
export const newMessage = async (channelId, text, file) => {
  try {
    if (file) {
      const { data } = await $authHost.post(`${channelId}/messages/create`, {
        text,
        files: file,
      });
      return data;
    } else {
      const { data } = await $authHost.post(`${channelId}/messages/create`, {
        text,
      });
      return data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      toast.error(e.response.data.message);
    } else {
      toast.error("Повідомлення не доставлено, оновіть сторінку");
    }
  }
};
