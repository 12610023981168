import { useEffect, useState } from "react";
import { endConsultations, banChat, unbanChat } from "../../http/custumersAPI";
import styles from "./styles/UsernameBox.module.css";
import { Avatar } from "react-chat-elements";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { consultationTime } from "./utils";
import ContentLoader from "react-content-loader";

const UsernameBox = ({
  avatar,
  name,
  status,
  clear,
  channel,
  fetchChannels,
  consultation,
  isUsernameBoxLoad,
  setShowList,
  setShowMessages,
}) => {
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [showEndModal, setShowEndModal] = useState(false);
  const [timeDiff, setTimeDiff] = useState(null);

  useEffect(() => {
    setTimeDiff(consultationTime(consultation?.endAt));

    const intervalId = setInterval(() => {
      if (consultation?.endAt) {
        setTimeDiff(consultationTime(consultation?.endAt));
      } else {
        setTimeDiff("Консультація не активна");
      }
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line
  }, [channel]);

  const handleBackClick = () => {
    clear();
    setShowList(true);
    setShowMessages(false);
  };

  const handleEndClick = async () => {
    try {
      const end = await endConsultations(channel);
      if (end) {
        clear();
        setShowEndModal(false);
        toast.success("Консультацію завершено");
        await fetchChannels();
      }
    } catch (error) {
      toast.error("Щось пішло не так, оновіть сторінку");
    }
  };

  const handleBlockClick = async () => {
    try {
      const ban = await banChat(channel);
      if (ban) {
        clear();
        setShowBlockModal(false);
        toast.success("Користувача заблоковано");
        await fetchChannels();
      }
    } catch (error) {
      toast.error("Щось пішло не так, оновіть сторінку");
    }
  };
  const handleUnblockClick = async () => {
    try {
      const unban = await unbanChat(channel);
      if (unban) {
        clear();
        toast.success("Користувача розблоковано");
        await fetchChannels();
      }
    } catch (error) {
      toast.error("Щось пішло не так, оновіть сторінку");
    }
  };
  return (
    <>
      <p className={styles.back_btn} onClick={handleBackClick}>
        Назад
      </p>
      <div className={styles.usernameDiv}>
        {isUsernameBoxLoad && (
          <div className={styles.usernameDivSkeleton}>
            <ContentLoader
              speed={2}
              backgroundColor="#404040"
              foregroundColor="#5c5c5c"
              viewBox="0 0 120 25"
              height={"100%"}
              width={"100%"}
            >
              <circle cx="8" cy="12.5" r="8" />
              <rect x="20" y="3" rx="2" ry="2" width="90" height="4" />
              <rect x="20" y="14" rx="2" ry="2" width="70" height="3" />
            </ContentLoader>
          </div>
        )}

        {avatar && (
          <>
            <Avatar src={avatar} alt="avatar" size="xlarge" type="circle" />
            <div className={styles.nameDiv}>
              <span className={styles.nameSpan}>{name}</span>
              {consultation?.endAt && status !== "end" && status !== "ban" && (
                <>
                  <br />
                  <span>{timeDiff}</span>
                </>
              )}
            </div>

            {status === "ban" ? (
              <button
                style={{ marginRight: "20px" }}
                className={styles.endBtn}
                onClick={() => setShowBlockModal(true)}
              >
                Розблокувати
              </button>
            ) : (
              <button
                style={{ marginRight: "20px" }}
                className={styles.endBtn}
                onClick={() => setShowBlockModal(true)}
              >
                Заблокувати
              </button>
            )}
            {status !== "end" && (
              <button
                style={{ marginLeft: "0px" }}
                className={styles.endBtn}
                onClick={() => setShowEndModal(true)}
              >
                Завершити
              </button>
            )}
          </>
        )}
        <Modal
          show={showBlockModal}
          onHide={() => {
            setShowBlockModal(false);
          }}
          centered
          className={styles.backdrop}
        >
          <Modal.Header style={{ justifyContent: "center" }}>
            {status === "ban"
              ? "Розблокувати користувача"
              : "Заблокувати користувача"}
          </Modal.Header>

          <Modal.Footer style={{ justifyContent: "center", gap: "25px" }}>
            <button
              className={styles.agree}
              onClick={(e) => {
                e.preventDefault();
                status === "ban" ? handleUnblockClick() : handleBlockClick();
                setShowBlockModal(false);
              }}
            >
              {status === "ban" ? "Розблокувати" : "Заблокувати"}
            </button>
            <button
              className={styles.disagree}
              onClick={() => setShowBlockModal(false)}
            >
              Відмінити
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showEndModal}
          onHide={() => {
            setShowEndModal(false);
          }}
          centered
          className={styles.backdrop}
        >
          <Modal.Header style={{ justifyContent: "center" }}>
            Завершити консультацію
          </Modal.Header>

          <Modal.Footer style={{ justifyContent: "center", gap: "25px" }}>
            <button
              className={styles.agree}
              onClick={(e) => {
                e.preventDefault();
                handleEndClick();
                setShowEndModal(false);
              }}
            >
              Завершити
            </button>
            <button
              className={styles.disagree}
              onClick={() => setShowEndModal(false)}
            >
              Відмінити
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default UsernameBox;
