import { $authHost } from "./index";

export const getChannels = async () => {
  const { data } = await $authHost.get(`channels/optimized`);
  console.log(data);

  const d = data.map((channel) => {
    if (!channel.lastMessage) {
      return {
        ...channel,
        messages: [],
      };
    }
    return {
      ...channel,
      messages: [
        {
          id: "6b6d0bb1-611e-4eb9-8084-6d211c03c848",
          type: "text",
          text: channel.lastMessage,
          createdAt: channel.lastMessageDate,
          updatedAt: channel.lastMessageDate,
        },
      ],
    };
  });

  console.log(d);

  return d;
};
