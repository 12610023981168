import React from "react";
import styles from "../styles/ChatsList.module.css";
import PinnedFile from "../pinned-items/PinnedFile";
import { getTime, getType } from "./";
import { URL } from "../../../utils/consts";

export const formatMessage = (m, chatId) => {
  if (m.type === "button_click") {
    return {
      position: "left",
      className: styles.actionMessage,
      type: "text",
      text: m.text,
      date: m.createdAt,
    };
  } else if (m.files[0])
    return {
      position: m.user.id === chatId ? "right" : "left",
      type: "text",
      title: (
        <div className={styles.divToFile}>
          <> {m.user.firstName}</>
          {m.files.map((file, index) => (
            <React.Fragment key={file.path}>
              <PinnedFile
                // fewf={console.log(file)}
                fileType={getType(file.type, file.path.split(".")[1])}
                fileURL={`${URL}${file.path}`}
                fileName={file.name}
                index={index}
              />
            </React.Fragment>
          ))}
        </div>
      ),

      text: `${m.text || ""}`,
      avatar:
        m.user.id === chatId
          ? "/manager.jpg"
          : m.user.avatarUrl
          ? `${URL}${m.user.avatarUrl}`
          : "/chatImg/1.jpg",

      date: m.createdAt,
    };
  else
    return {
      position: m.user.id === chatId ? "right" : "left",
      type: "text",
      text: m.text,
      date: m.createdAt,
      avatar:
        m.user.id === chatId
          ? "/manager.jpg"
          : m.user.avatarUrl
          ? `${URL}${m.user.avatarUrl}`
          : "/chatImg/1.jpg",
      title: m.user.firstName,
      dateString: getTime(m.createdAt),
    };
};
