import { AiOutlineInfoCircle } from "react-icons/ai";
// import { waitingTime } from "../utils/waitingTime";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getDiff } from "../utils/getDiff";
import { toLocalTimeFromIso } from "../utils/toLocalTimeFromIso";
import styles from "./styles/ConsultationsRow.module.css";

const ConsultationsRow = ({ user, consultation }) => {
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  return (
    <tr className={styles.userRow} key={user.id}>
      <td>{user.name || "Інкогніто"}</td>
      <td>{user.username || "---"}</td>

      <td>{user.age || "Невідомо"}</td>
      <td style={{ textTransform: "capitalize" }}>
        {user.messenger || "Невідомо"}
      </td>

      <td>{toLocalTimeFromIso(consultation.createAt) || "Невідомо"}</td>
      <td>
        {consultation.status === "in_progress" ? "В процесі" : "Завершено"}
      </td>

      <td>
        <button
          onClick={() => setShowModal(!showModal)}
          className={styles.startBtn}
        >
          <AiOutlineInfoCircle
            className={styles.startIcon}
          ></AiOutlineInfoCircle>
        </button>
      </td>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        centered
        className={styles.backdrop}
      >
        <Modal.Header style={{ justifyContent: "center" }}>
          Розширена інформація
        </Modal.Header>
        {consultation.status === "in_progress" ? (
          <Modal.Body className={styles.centered}>
            <h6>Менеджер: </h6>
            <input
              className={styles.disInput}
              disabled={true}
              value={`${consultation.manager?.name || "Невідомо"}`}
            />
            <h6>Початок консультації:</h6>
            <input
              className={styles.disInput}
              disabled={true}
              value={toLocalTimeFromIso(consultation.startAt)}
            />
            <h6>Орієнтовний час закінчення:</h6>
            <input
              className={styles.disInput}
              disabled={true}
              value={toLocalTimeFromIso(consultation.endAt)}
            />
            <h6>Швидкість відгуку:</h6>
            <input
              className={styles.disInput}
              disabled={true}
              value={getDiff(consultation.createAt, consultation.startAt)}
            />
          </Modal.Body>
        ) : (
          <Modal.Body className={styles.centered}>
            <h6>Менеджер: </h6>
            <input
              className={styles.disInput}
              disabled={true}
              value={`${consultation.manager?.name || "Невідомо"}`}
            />
            <h6>Початок консультації:</h6>
            <input
              className={styles.disInput}
              disabled={true}
              value={toLocalTimeFromIso(consultation.startAt)}
            />
            <h6>Кінець консультації:</h6>
            <input
              className={styles.disInput}
              disabled={true}
              value={toLocalTimeFromIso(consultation.endAt)}
            />
            <h6>Швидкість відгуку:</h6>
            <input
              className={styles.disInput}
              disabled={true}
              value={getDiff(consultation.createAt, consultation.startAt)}
            />
            <h6>Тривалість консультації:</h6>
            <input
              className={styles.disInput}
              disabled={true}
              value={getDiff(consultation.startAt, consultation.endAt)}
            />
            <h6>Оцінка:</h6>
            <input
              className={styles.disInput}
              disabled={true}
              value={consultation.rate || "Немає оцінки"}
            />
            <h6>Відгук:</h6>
            <textarea
              className={styles.disInput}
              disabled={true}
              value={consultation.feedback || "Відгук не залишено"}
            />
          </Modal.Body>
        )}

        <Modal.Footer style={{ justifyContent: "center", gap: "25px" }}>
          <button
            className={styles.agree}
            onClick={(e) => {
              e.preventDefault();

              setShowModal(false);
              navigate("/chat", {
                state: {
                  idChat: user.channel.id,
                },
              });
            }}
          >
            {consultation.status === "in_progress" ? "До чату" : "Історія"}
          </button>
          <button
            className={styles.disagree}
            onClick={() => setShowModal(false)}
          >
            Закрити
          </button>
        </Modal.Footer>
      </Modal>
    </tr>
  );
};

export default ConsultationsRow;
