// import { AiOutlineMessage } from "react-icons/ai";
// import styles from "./styles/ManagerRow.module.css";

const ManagerRow = ({ user }) => {
  return (
    <tr key={user.id}>
      <td>{user.name || "Інкогніто"}</td>
      <td>{user.login || "---"}</td>

      {/* <td>
        <button onClick={handleBtnClick} className={styles.startBtn}>
          <AiOutlineMessage className={styles.startIcon}></AiOutlineMessage>
        </button>
        <button onClick={handleBtnClick} className={styles.startBtn}>
          <AiOutlineMessage className={styles.startIcon}></AiOutlineMessage>
        </button>
      </td> */}
    </tr>
  );
};

export default ManagerRow;
