import { useEffect, useRef, useState } from "react";
import mk from "../sound/1.mp3";
import styles from "./styles/Sound.module.css";
import { Modal } from "react-bootstrap";
import { FaRegPlayCircle, FaRegPauseCircle } from "react-icons/fa";
import { useAuth } from "../AuthContext";
import newMessageSound from "../sound/newMessage.mp3";

const Sound = ({
  isPlay,
  isMessageSoundPlay,
  setShowSoundModal,
  showSoundModal,
}) => {
  const audioRef = useRef(null);
  const audioTestRef = useRef(null);
  const audioMessageRef = useRef(null);
  const [testSound, setTestSound] = useState(false);
  const { isAuth } = useAuth();

  useEffect(() => {
    if (isPlay && !showSoundModal) {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    } else {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, [isPlay]);
  useEffect(() => {
    if (isMessageSoundPlay && !showSoundModal) {
      audioMessageRef.current.currentTime = 0;
      audioMessageRef.current.play();
    }
  }, [isMessageSoundPlay]);

  const handleTestSoundBtn = () => {
    if (testSound) {
      audioTestRef.current.pause();
    } else {
      audioTestRef.current.play();
    }
    setTestSound((prev) => !prev);
  };

  const handleAudioEnded = () => {
    setTestSound(false);
  };

  return (
    <>
      <Modal
        show={showSoundModal && isAuth}
        onHide={() => {
          setShowSoundModal(false);
        }}
        centered
        className={styles.backdrop}
      >
        <Modal.Header style={{ justifyContent: "center" }}>
          Налаштуйте гучність свого пристрою перед початком роботи
        </Modal.Header>

        <Modal.Footer style={{ justifyContent: "center", gap: "25px" }}>
          <button
            className={styles.agree}
            style={{ fontSize: "12px" }}
            onClick={(e) => {
              e.preventDefault();
              handleTestSoundBtn();
            }}
          >
            {testSound ? (
              <FaRegPauseCircle style={{ width: "20px", height: "20px" }} />
            ) : (
              <FaRegPlayCircle style={{ width: "20px", height: "20px" }} />
            )}
          </button>
          <button
            className={styles.agree}
            onClick={(e) => {
              e.preventDefault();
              setShowSoundModal(false);
            }}
          >
            Підтвердити
          </button>
        </Modal.Footer>
      </Modal>
      <audio ref={audioRef} src={mk} style={{ display: "none" }} />
      <audio
        ref={audioTestRef}
        src={mk}
        style={{ display: "none" }}
        onEnded={handleAudioEnded}
      />
      <audio
        ref={audioMessageRef}
        src={newMessageSound}
        style={{ display: "none" }}
      />
    </>
  );
};

export default Sound;
