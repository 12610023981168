import PinnedFile from "./pinned-items/PinnedFile";
import styles from "./styles/PinnedFilesBox.module.css";
import { URL } from "../../utils/consts";

const PinnedFilesBox = ({ file, setFile }) => {
  const removeFile = (indexToRemove) => {
    setFile((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
  };
  return (
    <div className={styles.fileList}>
      {file.map((f, index) => (
        <PinnedFile
          key={index}
          fileType={f.type.split("/")[0]}
          fileURL={`${URL}${f.path}`}
          fileName={f.name}
          onRemove={removeFile}
          index={index}
        />
      ))}
    </div>
  );
};

export default PinnedFilesBox;
