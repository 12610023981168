import styles from "./styles/InputBox.module.css";
import { BsSend, BsPaperclip } from "react-icons/bs";

import { toast } from "react-toastify";
import { newMessage } from "../../http/messagesAPI";
import { newFile } from "../../http/filesAPI";
import { scrollToBottom } from "./utils";
import { useState } from "react";

const InputBox = ({
  chatId,
  channel,
  status,
  setText,
  text,
  setFile,
  file,
}) => {
  const [sending, setSending] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const handleFileUpload = async (event) => {
    const selectedFiles = event.target.files;

    const uploadedFiles = file || [];

    for (const selectedFile of selectedFiles) {
      if (selectedFile.size < 50000000) {
        const formData = new FormData();
        formData.append("file", selectedFile);

        try {
          setIsLoad(true);
          toast.info("Розпочато завантаження файла");

          const response = await newFile(formData);
          uploadedFiles.push(response);

          toast.success("Файл завантажено успішно!");
        } catch (error) {
          toast.error("Щось пішло не так. Спробуйте ще раз");
        } finally {
          setIsLoad(false);
        }
      } else {
        toast.error(
          `Розмір файла ${selectedFile.name} перевищує допустимі 50 мегабайт`
        );
      }
    }

    if (uploadedFiles) {
      setFile(uploadedFiles);
    } else {
      toast.error("Не завантажено жодного файлу");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      if (sending) {
        return;
      }
      e.preventDefault();
      handleSendMessage();
    }
  };
  const handleSendMessage = async () => {
    if (sending) {
      return;
    }
    if (status === "end" || status === "ban") {
      return;
    }

    if (isLoad) {
      toast.info("Зачекайте поки завантажиться файл");
      return;
    }
    if (channel === "") {
      toast.info("Спочатку виберіть чат");
      return;
    }
    if (text.trim() === "" && !file) {
      toast.info("Введіть повідомлення або виберіть файл");
      return;
    }
    setSending(true);

    try {
      await newMessage(channel, text, file);

      setText("");
      setFile(null);
      setTimeout(function () {
        scrollToBottom();
      }, 1);
    } catch (error) {
      toast.error("Повідомлення не доставлено. Спробуйте ще раз");
    } finally {
      setSending(false);
    }
  };
  return (
    <div className={styles.inputDiv}>
      {!isLoad && (
        <div>
          {!sending &&
            chatId &&
            channel &&
            status !== "end" &&
            status !== "ban" && (
              <div className={styles.btnDiv}>
                <input
                  type="file"
                  id="fileInput"
                  className={styles.hidden_input}
                  onChange={handleFileUpload}
                  multiple
                />
                <label htmlFor="fileInput" className={styles.custom_label}>
                  <BsPaperclip color="#1e1e1c" className={styles.fileSvg} />
                </label>
              </div>
            )}
        </div>
      )}

      <textarea
        placeholder={
          !sending && chatId && channel && status !== "end" && status !== "ban"
            ? "Введіть повідомлення..."
            : !sending &&
              chatId &&
              !channel &&
              status !== "end" &&
              status !== "ban"
            ? "Виберіть чат"
            : "Ви не можете надсилати повідомлення"
        }
        className={styles.chatInput}
        value={text}
        onChange={(e) => {
          setText(e.target.value);
        }}
        onKeyDown={handleKeyDown}
        disabled={
          !sending && chatId && channel && status !== "end" && status !== "ban"
            ? false
            : true
        }
      />

      {!isLoad &&
        !sending &&
        chatId &&
        channel &&
        status !== "end" &&
        status !== "ban" && (
          <div className={styles.btnDiv}>
            <button
              className={styles.btn}
              onClick={handleSendMessage}
              disabled={
                !sending &&
                chatId &&
                channel &&
                status !== "end" &&
                status !== "ban"
                  ? false
                  : true
              }
            >
              <BsSend color="#1e1e1c" className={styles.fileSvg} />
            </button>
          </div>
        )}
      {chatId && isLoad && (
        <p
          style={{
            margin: "auto",
            marginRight: "10px",
          }}
        >
          Триває завантаження файлів, зачекайте
        </p>
      )}
    </div>
  );
};

export default InputBox;
