import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const AverageAge = ({ data }) => {
  const chartData = {
    labels: data.months,
    datasets: [
      {
        label: "",
        data: data.averageAge,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div>
      {data && (
        <>
          <h2>Середній вік</h2> <Line data={chartData} options={options} />
        </>
      )}
    </div>
  );
};

export default AverageAge;
