import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { getActual } from "../http/custumersAPI";
import ActualsRow from "../components/ActualsRow";
import styles from "./styles/Actual.module.css";
import { sortedForActual } from "../utils/sortedForActual";
import { toast } from "react-toastify";
import RcPagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { useAuth } from "../AuthContext";

const Consultation = ({ socket, setIsPlay, setIsMessageSoundPlay }) => {
  const { chatId } = useAuth();
  const [consultations, setConsultations] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const usersPerPage = 10;

  const handleNotificationClick = () => {
    setIsPlay(0);
  };

  useEffect(() => {
    socket.on("message", async (newMessage) => {
      const storedChatIds = JSON.parse(localStorage.getItem("chatIds")) || [];
      if (
        storedChatIds.includes(newMessage.channelId) &&
        newMessage.type === "text" &&
        chatId !== newMessage.user.id
      ) {
        const truncatedText =
          newMessage.text.length > 50
            ? `${newMessage.text.slice(0, 50)}...`
            : newMessage.text;
        toast.info(
          `Нове повідомлення\n${newMessage.user.firstName}:\n ${truncatedText}`
        );
        setIsMessageSoundPlay((prev) => (prev += 1));
      }
    });
    socket.on("newConsultation", () => {
      toast.info("Зявився новий запит", {
        onClick: handleNotificationClick,
        autoClose: 9000,
      });
      setIsPlay((prev) => (prev += 1));

      const fetchConsultations = async () => {
        try {
          const users = await getActual();
          const sorted = sortedForActual(users);
          setConsultations(sorted);
        } catch (error) {}
      };
      fetchConsultations();
    });
    return () => {
      socket.off("newConsultation");
      socket.off("message");
    };
  }, [socket]);

  const fetchConsultations = async () => {
    try {
      const users = await getActual();
      const sorted = sortedForActual(users);
      setConsultations(sorted);
      function порівнятиДати(дата1, дата2) {
        const першаДата = new Date(дата1);
        const другаДата = new Date(дата2);

        const рікПершоїДати = першаДата.getFullYear();
        const місяцьПершоїДати = першаДата.getMonth();
        const деньПершоїДати = першаДата.getDate();

        const рікДругоДати = другаДата.getFullYear();
        const місяцьДругоДати = другаДата.getMonth();
        const деньДругоДати = другаДата.getDate();

        return (
          рікПершоїДати === рікДругоДати &&
          місяцьПершоїДати === місяцьДругоДати &&
          деньПершоїДати === деньДругоДати
        );
      }

      // Приклад використання:
      const обєкт1 = { дата: "2023-11-19T23:31:20.840Z" };
      const обєкт2 = { дата: "2023-11-20T01:45:30.120Z" };

      const результат = порівнятиДати(обєкт1.дата, обєкт2.дата);
      console.log(результат); //
    } catch (error) {}
  };

  useEffect(() => {
    const fetchCons = async () => {
      try {
        await fetchConsultations();
      } catch (error) {}
    };
    fetchCons();
  }, []);

  const indexOfLastUser = (currentPage + 1) * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  return (
    <>
      <div className={styles.userTable}>
        <Table page-size="4">
          <thead>
            <tr style={{ fontWeight: "600" }}>
              <th style={{ width: "17vw" }}>Ім'я</th>
              <th style={{ width: "19vw" }}>Юзернейм</th>
              <th style={{ width: "6vw" }}>Вік</th>
              <th style={{ width: "14vw" }}>Мессенджер</th>
              <th style={{ width: "14vw" }}>Час звернення</th>
              <th style={{ width: "14vw" }}>Час очікування</th>
              <th style={{ width: "7vw" }}>Дії</th>
            </tr>
          </thead>

          <tbody>
            {consultations
              .slice(indexOfFirstUser, indexOfLastUser)
              .map((user) => (
                <ActualsRow
                  key={user.id}
                  user={user.user}
                  idForChat={user.id}
                  consultation={user}
                  fetchConsultations={fetchConsultations}
                />
              ))}
          </tbody>
        </Table>
      </div>

      {consultations.length > usersPerPage && (
        <RcPagination
          current={currentPage + 1}
          total={consultations.length}
          pageSize={usersPerPage}
          onChange={(page) => setCurrentPage(page - 1)}
          className={styles.paginationContainer}
        />
      )}
    </>
  );
};

export default Consultation;
