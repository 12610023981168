import { getDay } from "../components/chat/utils/getDay";

function compareDates(date1, date2) {
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);

  const yearFirstDate = firstDate.getFullYear();
  const monthFirstDate = firstDate.getMonth();
  const dayFirstDate = firstDate.getDate();

  const yearSecondDate = secondDate.getFullYear();
  const monthSecondDate = secondDate.getMonth();
  const daySecondDate = secondDate.getDate();

  return (
    yearFirstDate === yearSecondDate &&
    monthFirstDate === monthSecondDate &&
    dayFirstDate === daySecondDate
  );
}
export const insertObjectBetween = (array) => {
  if (array.length === 0) {
    return array;
  }
  if (array.length === 1) {
    const firstObject = array[0];
    const newObject = {
      date: firstObject.date,
      className: "newDay",
      position: "left",
      text: getDay(firstObject.date),
      type: "text",
    };
    array.unshift(newObject);
    return array;
  }
  let i = 0;
  while (i < array.length - 1) {
    const currentObject = array[i];
    const nextObject = array[i + 1];
    if (nextObject.className === "newDay" || currentObject.class === "newDay") {
      i++;
    } else if (!compareDates(currentObject.date, nextObject.date)) {
      const newObject = {
        date: nextObject.date,
        className: "newDay",
        position: "left",
        text: getDay(nextObject.date),
        type: "text",
      };

      array.splice(i + 1, 0, newObject);
      i += 2;
    } else {
      i++;
    }
  }

  const firstObject = array[0];
  const newObject = {
    date: firstObject.date,
    className: "newDay",
    position: "left",
    text: getDay(firstObject.date),
    type: "text",
  };
  array.unshift(newObject);

  return array;
};
