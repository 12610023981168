import { createContext, useState, useContext } from "react";
const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [chatId, setChatId] = useState(null);
  const out = () => {
    setIsAuth(false);
    setIsAdmin(false);
    setChatId(null);
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("isAdmin");
  };

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        isAdmin,
        chatId,
        setChatId,
        setIsAdmin,
        setIsAuth,
        out,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
