import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const SexCount = ({ data }) => {
  const chartData = {
    labels: data.months,
    datasets: [
      {
        label: "Чоловіки",
        data: data.menCount,
      },
      {
        label: "Жінки",
        data: data.womenCount,
      },
    ],
  };
  const options = {
    scales: {
      x: {
        type: "category",
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      <h2>Стать</h2> <Line data={chartData} options={options} />
    </>
  );
};

export default SexCount;
