import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const AvaragePeriodByAgeCategories = ({ data }) => {
  const mappedObject = Object.keys(data.avarageAgeByAgeCategories).map(
    (key) => ({
      key: key,
      value: data.avarageAgeByAgeCategories[key],
    })
  );
  const datasets = mappedObject.map((i) => {
    return {
      label: i.key,
      data: i.value,
      key: i.key,
    };
  });

  const chartData = {
    labels: data.months,
    datasets: datasets,
  };
  const options = {
    scales: {
      x: {
        type: "category",
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      <h2>Вікові групи</h2> <Line data={chartData} options={options} />
    </>
  );
};

export default AvaragePeriodByAgeCategories;
