export const getDiff = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  const timeDifference = endDate - startDate;
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  if (hours > 0) {
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${hours} годин ${formattedMinutes} хвилин`;
  } else {
    return `${minutes} хвилин`;
  }
};
