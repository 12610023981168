import { toast } from "react-toastify";
import { $authHost } from "./index";

export const getAll = async () => {
  try {
    const { data } = await $authHost.get("customers/users", {});
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const getActual = async () => {
  try {
    const { data } = await $authHost.get("consultations/actual", {});
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const getConsultations = async () => {
  try {
    const { data } = await $authHost.get("consultations/", {});
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const takeConsultations = async (userId) => {
  try {
    const { data } = await $authHost.put(`consultations/${userId}`, {});
    return data;
  } catch (e) {
    if (e.response.status === 403) {
      toast.info("Ви перевищили ліміт активних консультацій");
    }
    console.log(e);
  }
};
export const deleteConsultations = async (userId) => {
  try {
    const { data } = await $authHost.delete(
      `consultations/consultation/${userId}`
    );
    console.log(data);
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const endConsultations = async (channelId) => {
  try {
    const { data } = await $authHost.put(
      `consultations/channel/${channelId}/end`
    );
    return data;
  } catch (e) {
    if (e.response.status === 404) {
      toast.info("Консультацію не знайдено");
    } else {
      toast.error("Щось пішло не так, оновіть сторінку");
    }
    console.log(e);
  }
};
export const banChat = async (channelId) => {
  try {
    const { data } = await $authHost.post(`ban/${channelId}/block`);
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const unbanChat = async (channelId) => {
  try {
    const { data } = await $authHost.post(`ban/${channelId}/unblock`);
    return data;
  } catch (e) {
    console.log(e);
  }
};
