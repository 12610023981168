import { useState } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../AuthContext";
import { login } from "../http/managerAPI";
import styles from "./styles/Login.module.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const { setIsAuth, setIsAdmin, setChatId } = useAuth();

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      await handleLoginClick();
    }
  };
  const handleLoginClick = async () => {
    try {
      const user = await login(username, password);
      if (user) {
        setIsAdmin(user.isAdmin);
        setIsAuth(true);
        setChatId(user.chatUser.id);

        localStorage.setItem("chatId", user.chatUser.id);
      } else {
        toast.error("Неправильний логін або пароль");
      }
    } catch (error) {}
  };

  return (
    <div className={styles.screen}>
      <main className={styles.loginContainer}>
        <div className={styles.loginDiv}>
          <h1 className={styles.logo}>2345</h1>
          <h2 className={styles.loginTitle}>Авторизація</h2>
          <input
            className={styles.loginInput}
            type="text"
            placeholder="Логін"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <div className={styles.passwordInputContainer}>
            <input
              className={`${styles.passwordInput} ${styles.loginInput}`}
              type={showPassword ? "text" : "password"}
              placeholder="Пароль"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <button
              className={styles.showPasswordButton}
              onClick={() => setShowPassword(!showPassword)}
            >
              <img
                src={
                  showPassword ? "/loginImg/eye.slash.svg" : "/loginImg/eye.svg"
                }
                alt={showPassword ? "Скрити пароль" : "Показати пароль"}
                className={styles.loginSvg}
              />
            </button>
          </div>
          <button className={styles.loginBtn} onClick={handleLoginClick}>
            Увійти
          </button>
        </div>
      </main>
    </div>
  );
};

export default Login;
