import { Link, useLocation } from "react-router-dom";
import {
  ACTUALS_ROUTE,
  CONSULTATIONS_ROUTE,
  CHAT_ROUTE,
  MANAGERS_ROUTE,
  STATISTICS_ROUTE,
} from "../utils/consts";
import { useAuth } from "../AuthContext";
import { logout } from "../http/managerAPI";
import styles from "./styles/NavBar.module.css";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

const NavBar = () => {
  const { out, isAdmin } = useAuth();
  const location = useLocation();

  const [showMenu, setShowMenu] = useState(false);
  const [activeLink, setActiveLink] = useState("");

  const handleLogout = async () => {
    try {
      await logout();
      out();
    } catch (error) {
      toast("Щось пішло не так, оновіть сторінку");
    }
  };

  useEffect(() => {
    if (location.pathname === ACTUALS_ROUTE) {
      setActiveLink("Запити");
    } else if (location.pathname === CONSULTATIONS_ROUTE) {
      setActiveLink("Консультація");
    } else if (location.pathname === CHAT_ROUTE) {
      setActiveLink("Чат");
    } else if (location.pathname === MANAGERS_ROUTE) {
      setActiveLink("Менеджери");
    } else if (location.pathname === STATISTICS_ROUTE) {
      setActiveLink("Статистика");
    }
  }, [location.pathname]);

  return (
    <header className={styles.navBar}>
      <div className={styles.navAvatar}>
        <div
          className={styles.avatar}
          style={{ backgroundImage: `url('/manager.jpg')` }}
        ></div>
        <span className={styles.managerInfo}>
          {localStorage.getItem("name")}
        </span>
      </div>

      <div className={styles.rigthSide}>
        {isAdmin && (
          <Link
            to={MANAGERS_ROUTE}
            className={`${styles.navBtn} ${
              location.pathname === MANAGERS_ROUTE ? styles.selectedPage : ""
            }`}
          >
            Менеджери
          </Link>
        )}
        <Link
          to={STATISTICS_ROUTE}
          className={`${styles.navBtn} ${
            location.pathname === STATISTICS_ROUTE ? styles.selectedPage : ""
          }`}
        >
          Статистика
        </Link>
        <Link
          to={CHAT_ROUTE}
          className={`${styles.navBtn} ${
            location.pathname === CHAT_ROUTE ? styles.selectedPage : ""
          }`}
        >
          Чат
        </Link>
        <Link
          to={ACTUALS_ROUTE}
          className={`${styles.navBtn} ${
            location.pathname === ACTUALS_ROUTE ? styles.selectedPage : ""
          }`}
        >
          Запити
        </Link>

        <Link
          to={CONSULTATIONS_ROUTE}
          className={`${styles.navBtn} ${
            location.pathname === CONSULTATIONS_ROUTE ? styles.selectedPage : ""
          }`}
        >
          Консультації
        </Link>

        <button onClick={handleLogout} className={`${styles.exitBtn}`}>
          Вийти
        </button>
      </div>
      <div className={styles.rigthSide_mobile}>
        <div className={styles.select_container}>
          <p
            className={`${styles.navBtn} ${styles.selectedPage} ${
              showMenu ? styles.btn_withoutBorder : ""
            }`}
            onClick={() => setShowMenu(true)}
          >
            {activeLink}
          </p>
          <div
            className={`${styles.select_wrapper} ${
              showMenu ? styles.show_select : ""
            }`}
          >
            {isAdmin && (
              <Link
                to={MANAGERS_ROUTE}
                className={`${styles.navBtn} `}
                onClick={() => {
                  setShowMenu(false);
                  setActiveLink("Менеджери");
                }}
              >
                Менеджери
              </Link>
            )}
            <Link
              to={STATISTICS_ROUTE}
              className={`${styles.navBtn} `}
              onClick={() => {
                setShowMenu(false);
                setActiveLink("Статистика");
              }}
            >
              Статистика
            </Link>
            <Link
              to={CHAT_ROUTE}
              className={`${styles.navBtn} `}
              onClick={() => {
                setShowMenu(false);
                setActiveLink("Чат");
              }}
            >
              Чат
            </Link>
            <Link
              to={ACTUALS_ROUTE}
              className={`${styles.navBtn} `}
              onClick={() => {
                setShowMenu(false);
                setActiveLink("Запити");
              }}
            >
              Запити
            </Link>
            <Link
              to={CONSULTATIONS_ROUTE}
              className={`${styles.navBtn} `}
              onClick={() => {
                setShowMenu(false);
                setActiveLink("Консультації");
              }}
            >
              Консультації
            </Link>
          </div>
        </div>

        <button onClick={handleLogout} className={`${styles.exitBtn}`}>
          Вийти
        </button>
      </div>
    </header>
  );
};

export default NavBar;
