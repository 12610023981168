import { $host, $authHost } from "./index";

export const login = async (login, password) => {
  try {
    const { data } = await $host.post("manager/login", {
      login,
      password,
    });
    console.log(data);
    localStorage.setItem("token", data.data.token);
    localStorage.setItem("name", data.data.name);
    localStorage.setItem("isAdmin", data.data.isAdmin);
    return data.data;
  } catch (e) {
    console.log(e);
  }
};

export const logout = async () => {
  try {
    const { data } = await $authHost.post("manager/logout", {});
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const refresh = async () => {
  try {
    const user = await $authHost.get("manager");
    return user;
  } catch (error) {
    console.log(error);
  }
};
export const getStatistics = async () => {
  try {
    const { data } = await $authHost.get("statistics");
    return data;
  } catch (error) {
    // console.log(error);
  }
};
export const getManagers = async () => {
  try {
    const { data } = await $authHost.get("admin/managers");
    return data.data;
  } catch (error) {
    console.log(error);
  }
};
export const newManager = async (name, login, password) => {
  try {
    const { data } = await $authHost.post("admin/create", {
      name,
      login,
      password,
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};
