import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { authRoutes, publicRoutes, adminRoutes } from "../routes";
import { useAuth } from "../AuthContext";
import { LOGIN_ROUTE, ACTUALS_ROUTE } from "../utils/consts";

const AppRouter = ({ socket, setIsPlay, setIsMessageSoundPlay }) => {
  const { isAuth, isAdmin } = useAuth();

  const privateRoute = ({ path, Component }) => {
    return (
      <Route
        key={path}
        path={path}
        element={
          <Component
            socket={socket}
            setIsPlay={setIsPlay}
            setIsMessageSoundPlay={setIsMessageSoundPlay}
          />
        }
      />
    );
  };

  return (
    <Routes>
      {isAuth &&
        authRoutes.map(({ path, Component }) =>
          privateRoute({ path, Component })
        )}

      {isAuth &&
        isAdmin &&
        adminRoutes.map(({ path, Component }) =>
          privateRoute({ path, Component })
        )}

      {!isAuth &&
        publicRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} exact />
        ))}

      <Route
        key="fallback"
        path="*"
        element={<Navigate to={isAuth ? ACTUALS_ROUTE : LOGIN_ROUTE} />}
      />

      <Route element={<Outlet />} />
    </Routes>
  );
};

export default AppRouter;
