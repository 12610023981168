import { DateTime } from "luxon";

export const waitingTime = (requestTime) => {
  const dateTimeUTC = DateTime.fromISO(requestTime, { zone: "utc" });
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateTime = dateTimeUTC.setZone(userTimeZone);
  const parsedRequestDate = new Date(dateTime);
  const currentDate = new Date();

  const timeDifference = currentDate - parsedRequestDate;

  const daysDiff = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  if (daysDiff > 0) {
    return "Більше доби!";
  }
  const hoursDiff = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutesDiff = Math.floor(
    (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
  );

  const formattedHours = hoursDiff < 10 ? `0${hoursDiff}` : `${hoursDiff}`;
  const formattedMinutes =
    minutesDiff < 10 ? `0${minutesDiff}` : `${minutesDiff}`;

  return `${formattedHours}:${formattedMinutes}`;
};
