import { AiOutlineMessage } from "react-icons/ai";
import { waitingTime } from "../utils/waitingTime";
import styles from "./styles/ActualsRow.module.css";
import {
  takeConsultations,
  deleteConsultations,
  banChat,
  unbanChat,
} from "../http/custumersAPI";
import { toast } from "react-toastify";
import { toLocalTimeFromIso } from "../utils/toLocalTimeFromIso";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { getMessages } from "../http/messagesAPI";
import { MessageList } from "react-chat-elements";
import { URL } from "../utils/consts";

const ActualsRow = ({ user, idForChat, consultation, fetchConsultations }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [messages, setMessages] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const defaultUser = {
    id: 1,
    name: 'Стандартне Ім\'я',
    username: 'Стандартний Юзернейм',
    age: 'Невідомо',
    messenger: 'Невідомо',
    avatarUrl: '/chatImg/default.jpg',
    channel: { id: 'default-channel' }
  };

  user = user || defaultUser;


  const handleStartClick = async () => {
    try {
      const ok = await takeConsultations(idForChat);
      if (ok) {
        toast.success("Консультацію розпочато");
        navigate("/chat", {
          state: {
            idChat: user.channel.id,
          },
        });
      }
    } catch (error) {
      toast.error("Щось пішло не так, оновіть сторінку");
    }
  };

  const handleDeleteClick = async () => {
    try {
      const done = await deleteConsultations(consultation.id);
      await fetchConsultations();
      setShowDeleteModal(false);
      setShowModal(false);
      if (done) {
        toast.info("Консультацію видалено успішно");
      }
    } catch (error) {}
  };
  const formatMessage = (m) => {
    if (m.type === "button_click") {
      return {
        position: "left",
        className: styles.actionMessage,
        type: "text",
        text: m.text,
      };
    } else if (m.files[0])
      return {
        position: "left",
        type: "text",
        title: (
            <div className={styles.divToFile}>
              {m.files && m.files.map((file, index) => (
                  <>
                    {m.user && <> {m.user.firstName}</>}
                    <a
                        key={index}
                        className={styles.linkToFile}
                        href={`${URL}${file.path}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(e.currentTarget.href, "_blank");
                        }}
                    >
                      Вкладений файл {index + 1}
                    </a>
                  </>
              ))}
            </div>
        ),


        text: `${m.text || ""}`,
        avatar: m.user.avatarUrl
            ? `${URL}${m.user.avatarUrl}`
            : "/chatImg/1.jpg",

        date: m.createdAt,
        className: styles.message,
      };
    else
      return {
        position: "left",
        type: "text",
        text: m.text,
        date: m.createdAt,
        title: m.user.firstName,
        className: styles.message,
        avatar: m.user.avatarUrl
            ? `${URL}${m.user.avatarUrl}`
            : "/chatImg/1.jpg",
      };
  };

  const handleModalClick = () => {
    const asd = async () => {
      const data = await getMessages(user.channel?.id);

      const formattedMessages = data.map((m) => formatMessage(m));
      setMessages(formattedMessages);
      setShowModal(true);
    };
    asd();
  };

  const handleBlockClick = async () => {
    try {
      const ban = await banChat(user.channel.id);
      if (ban) {
        await fetchConsultations();
        setShowBlockModal(false);
        setShowModal(false);
        toast.success("Користувача заблоковано");
      }
    } catch (error) {
      toast.error("Щось пішло не так, оновіть сторінку");
    }
  };
  const handleUnblockClick = async () => {
    try {
      const unban = await unbanChat(user.channel.id);
      if (unban) {
        await fetchConsultations();
        setShowBlockModal(false);
        setShowModal(false);
        toast.success("Користувача розблоковано");
      }
    } catch (error) {
      toast.error("Щось пішло не так, оновіть сторінку");
    }
  };
  return (
      <tr key={user.id}>
        <td>{user.name}</td>
        <td>{user.username}</td>
        <td>{user.age}</td>
        <td style={{textTransform: "capitalize"}}>{user.messenger}</td>
        <td>{toLocalTimeFromIso(consultation.createAt) || "Невідомо"}</td>
        <td>{waitingTime(consultation.createAt)}</td>

        <td>
          <button onClick={handleModalClick} className={styles.startBtn}>
            <AiOutlineMessage className={styles.startIcon}/>
          </button>
        </td>
        <Modal
            show={showModal}
            onHide={() => {
              setShowModal(false);
            }}
            centered
            className={styles.backdrop}
        >
          <Modal.Header style={{justifyContent: "center"}}>
            <h3>{user.name}</h3>
          </Modal.Header>
          <Modal.Body className={styles.modal}>
            <MessageList
                className={styles.messageList}
                toBottomHeight={"100%"}
                dataSource={messages}
            />
          </Modal.Body>
          <Modal.Footer style={{justifyContent: "center"}}>
            <button className={styles.agree} onClick={handleStartClick}>
              Почати діалог
            </button>
            <button
                className={styles.agree}
                onClick={() => setShowDeleteModal(true)}
            >
              Видалити запит
            </button>
            <button
                className={styles.agree}
                onClick={() => setShowBlockModal(true)}
            >
              Заблокувати користувача
            </button>
            <button
                className={styles.disagree}
                onClick={() => {
                  setShowModal(false);
                }}
            >
              Закрити
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
            show={showDeleteModal}
            onHide={() => {
              setShowDeleteModal(false);
            }}
            centered
            className={styles.backdrop}
        >
          <Modal.Header style={{justifyContent: "center"}}>
            Видалити запит
          </Modal.Header>

          <Modal.Footer style={{justifyContent: "center", gap: "25px"}}>
            <button
                className={styles.agree}
                onClick={(e) => {
                  e.preventDefault();
                  handleDeleteClick();
                  setShowDeleteModal(false);
                }}
            >
              Видалити
            </button>
            <button
                className={styles.disagree}
                onClick={() => setShowDeleteModal(false)}
            >
              Відмінити
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
            show={showBlockModal}
            onHide={() => {
              setShowBlockModal(false);
            }}
            centered
            className={styles.backdrop}
        >
          <Modal.Header style={{justifyContent: "center"}}>
            {user.isBanned
                ? "Розблокувати користувача"
                : "Заблокувати користувача"}
          </Modal.Header>

          <Modal.Footer style={{justifyContent: "center", gap: "25px"}}>
            <button
                className={styles.agree}
                onClick={(e) => {
                  e.preventDefault();
                  user.isBanned ? handleUnblockClick() : handleBlockClick();
                  setShowBlockModal(false);
                }}
            >
              {user.isBanned ? "Розблокувати" : "Заблокувати"}
            </button>
            <button
                className={styles.disagree}
                onClick={() => setShowBlockModal(false)}
            >
              Відмінити
            </button>
          </Modal.Footer>
        </Modal>
      </tr>
  );
};

export default ActualsRow;
