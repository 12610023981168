export const consultationTime = (endTime) => {
  if (!endTime) {
    return "Консультація не активна";
  }
  const currentTime = new Date();
  const endTimeDate = new Date(endTime);
  const timeDiff = endTimeDate - currentTime;

  if (timeDiff < 0) {
    const asd = timeDiff * -1;
    const hours = Math.floor(asd / (1000 * 60 * 60));
    const minutes = Math.floor((asd % (1000 * 60 * 60)) / (1000 * 60));
    if (isNaN(hours) || isNaN(minutes)) {
      return "Консультація не активна";
    }
    if (hours > 0) {
      if (minutes === 0) {
        return `Затримка: ${hours} година`;
      } else if (minutes === 1) {
        return `Затримка: ${hours} година 1 хвилина`;
      } else if (minutes > 1 && minutes < 5) {
        return `Затримка: ${hours} година ${minutes} хвилини`;
      } else {
        return `Затримка: ${hours} година ${minutes} хвилин`;
      }
    } else {
      if (minutes === 0) {
        return `Час вичерпано`;
      } else if (minutes === 1) {
        return `Затримка: 1 хвилина`;
      } else if (minutes > 1 && minutes < 5) {
        return `Затримка: ${minutes} хвилини`;
      } else return `Затримка: ${minutes} хвилин`;
    }
  } else {
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    if (isNaN(hours) || isNaN(minutes)) {
      return "Консультація не активна";
    }

    if (hours > 0) {
      if (minutes === 0) {
        return `Залишилось: ${hours} година`;
      } else if (minutes === 1) {
        return `Залишилось: ${hours} година 1 хвилина`;
      } else if (minutes > 1 && minutes < 5) {
        return `Залишилось: ${hours} година ${minutes} хвилини`;
      } else {
        return `Залишилось: ${hours} година ${minutes} хвилин`;
      }
    } else {
      if (minutes === 0) {
        return `Час вичерпано`;
      } else if (minutes === 1) {
        return `Залишилось: 1 хвилина`;
      } else if (minutes > 1 && minutes < 5) {
        return `Залишилось: ${minutes} хвилини`;
      } else return `Залишилось: ${minutes} хвилин`;
    }
  }
};
