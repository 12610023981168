import { getMessages } from "../../http/messagesAPI";
import styles from "./styles/ChatsList.module.css";
import { ChatItem } from "react-chat-elements";
import { toast } from "react-toastify";
import { formatMessage, scrollToBottom, getTime } from "./utils";
import { URL } from "../../utils/consts";
import RcPagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import ContentLoader from "react-content-loader";
import { insertObjectBetween } from "../../utils/insertObjectBetween";

const ChatsList = ({
  channels,
  channel,
  setAvatar,
  setChannel,
  setFile,
  setMessages,
  setStatus,
  setText,
  setName,
  chatId,
  setConsultation,
  isChatslistLoad,
  currentPage,
  setCurrentPage,
  setShowMessages,
  showList,
  setShowList,
}) => {
  const usersPerPage = 10;
  const indexOfLastUser = (currentPage + 1) * usersPerPage;

  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  function getAvatarUrlByParticipant(participants) {
    for (const participant of participants) {
      if (participant.isManager) continue;

      if (!participant.isManager && participant.avatarUrl) {
        return `${URL}${participant.avatarUrl}`;
      }

      return "/chatImg/1.jpg";
    }
  }

  const renderSkeletons = () => {
    const skeletonCount = 9;

    return Array.from({ length: skeletonCount }).map((_, index) => (
      <div key={index} className={styles.sceletonDiv}>
        <ContentLoader
          speed={2}
          backgroundColor="#404040"
          foregroundColor="#5c5c5c"
          viewBox="0 0 120 25"
        >
          <circle cx="8" cy="12.5" r="8" />
          <rect x="20" y="3" rx="2" ry="2" width="90" height="4" />
          <rect x="20" y="14" rx="2" ry="2" width="70" height="3" />
        </ContentLoader>
      </div>
    ));
  };
  const handleChatClick = async (c) => {
    try {
      setShowMessages(true);
      setShowList(false);
      const messagesForChannel = await getMessages(c.id);
      const formattedMessages = messagesForChannel.map((m) =>
        formatMessage(m, chatId)
      );
      setChannel(c.id);
      setConsultation(c.consultation);
      setStatus(c.status);
      setAvatar(getAvatarUrlByParticipant(c.participants));
      setName(c.name);
      setText("");
      // setMessages(formattedMessages);
      setMessages(insertObjectBetween(formattedMessages));
      setFile(null);

      setTimeout(function () {
        scrollToBottom();
      }, 1);
    } catch (error) {
      toast.error("Щось пішло не так. Спробуйте ще раз");
    }
  };
  return (
    <div className={`${!showList ? styles.hide_list : ""} ${styles.wp}`}>
      <div className={styles.chatsList}>
        {isChatslistLoad ? (
          <div className={styles.sceletonDiv}>{renderSkeletons()}</div>
        ) : (
          channels.slice(indexOfFirstUser, indexOfLastUser).map((c) => (
            <ChatItem
              key={c.id}
              onClick={() => handleChatClick(c)}
              avatar={getAvatarUrlByParticipant(c.participants)}
              className={`${c.id === channel ? styles.activeChat : ""} ${
                styles.chatItem
              }`}
              alt="avatar"
              title={
                c.status === "wait-manager"
                  ? `${c.name} (Очікує відповіді)`
                  : c.status === "ban"
                  ? `${c.name} (Заблоковано)`
                  : c.status === "wait-user"
                  ? `${c.name}`
                  : c.name
              }
              subtitle={
                c.messages.length > 0
                  ? (() => {
                      const latestMessage = c.messages.sort((a, b) =>
                        b.createdAt.localeCompare(a.createdAt)
                      )[0].text;
                      return latestMessage;
                    })()
                  : " "
              }
              dateString={
                c.messages.length > 0 && c.consultation?.status === "end"
                  ? `Завершено:  ${getTime(c.consultation.endAt)}`
                  : c.messages.length > 0
                  ? getTime(
                      c.messages.sort((a, b) =>
                        b.createdAt.localeCompare(a.createdAt)
                      )[0].createdAt
                    )
                  : " "
              }
              unread={0}
            />
          ))
        )}
      </div>
      {channels.length > usersPerPage && (
        <RcPagination
          current={currentPage + 1}
          total={channels.length}
          pageSize={usersPerPage}
          onChange={(page) => setCurrentPage(page - 1)}
          className={styles.paginationContainer}
        />
      )}
    </div>
  );
};

export default ChatsList;
