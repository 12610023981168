import {
  LOGIN_ROUTE,
  CONSULTATIONS_ROUTE,
  ACTUALS_ROUTE,
  CHAT_ROUTE,
  MANAGERS_ROUTE,
  STATISTICS_ROUTE,
} from "./utils/consts";
import Login from "./pages/Login";
import Consultation from "./pages/Consultation";
import Actual from "./pages/Actual";
import Chat from "./pages/Chat";
import Managers from "./pages/Managers";
import Statistics from "./pages/Statistics";

export const authRoutes = [
  {
    path: CONSULTATIONS_ROUTE,
    Component: Consultation,
  },
  {
    path: ACTUALS_ROUTE,
    Component: Actual,
  },
  {
    path: CHAT_ROUTE,
    Component: Chat,
  },
  {
    path: STATISTICS_ROUTE,
    Component: Statistics,
  },
];

export const publicRoutes = [
  {
    path: LOGIN_ROUTE,
    Component: Login,
  },
];

export const adminRoutes = [
  {
    path: MANAGERS_ROUTE,
    Component: Managers,
  },
];
