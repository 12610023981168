export const sortedForChats = (data) => {
  if (!data) {
    return [];
  }
  if (data.length < 2) {
    return data;
  }
  const channelsWithYoungestMessage = data.map((channel) => {
    const sortedMessages = channel.messages.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    const youngestMessage =
      sortedMessages.length > 0 ? sortedMessages[0].createdAt : new Date();
    return { ...channel, youngestMessage };
  });

  const sortedChannels = channelsWithYoungestMessage.sort((a, b) => {
    const dateA = new Date(a.youngestMessage);
    const dateB = new Date(b.youngestMessage);
    return dateB - dateA;
  });

  sortedChannels.sort((a, b) => {
    if (a.status === "end" && b.status !== "end") {
      return 1;
    } else if (a.status !== "end" && b.status === "end") {
      return -1;
    } else {
      return 0;
    }
  });
  sortedChannels.sort((a, b) => {
    if (
      a.status === "new" &&
      b.status === "new" &&
      a.messages.length === 0 &&
      b.messages.length === 0 &&
      a.id > b.id
    ) {
      return 1;
    } else if (
      a.status === "new" &&
      b.status === "new" &&
      a.messages.length === 0 &&
      b.messages.length === 0 &&
      a.id < b.id
    ) {
      return -1;
    } else {
      return 0;
    }
  });
  return sortedChannels;
};
