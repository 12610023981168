import PinnedAudio from "./PinnedAudio";
import PinnedDocument from "./PinnedDocument";
import PinnedImage from "./PinnedImage";
import PinnedVideo from "./PinnedVideo";
import UnpinButton from "./UnpinButton";

const PinnedFile = ({ fileURL, fileType, fileName, onRemove, index }) => {
  const removeFile = () => {
    onRemove(index);
  };

  return (
    <div
      key={fileURL}
      style={{
        display: "flex",
        position: "relative",
      }}
    >
      {fileType === "image" && <PinnedImage imageURL={fileURL} />}
      {fileType === "audio" && <PinnedAudio audioURL={fileURL} />}
      {fileType === "video" && <PinnedVideo videoURL={fileURL} />}
      {fileType !== "image" && fileType !== "audio" && fileType !== "video" && (
        <PinnedDocument documentURL={fileURL} documentName={fileName} />
      )}
      {onRemove && <UnpinButton onRemove={removeFile} />}
    </div>
  );
};

export default PinnedFile;
