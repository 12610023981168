export const getTime = (timeFromBackend) => {
  const backendDate = new Date(timeFromBackend);
  const currentDate = new Date();

  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
  const oneWeekInMilliseconds = 7 * oneDayInMilliseconds;

  const daysOfWeek = [
    "Неділя",
    "Понеділок",
    "Вівторок",
    "Середа",
    "Четвер",
    "П'ятниця",
    "Субота",
  ];

  const months = [
    "січня",
    "лютого",
    "березня",
    "квітня",
    "травня",
    "червня",
    "липня",
    "серпня",
    "вересня",
    "жовтня",
    "листопада",
    "грудня",
  ];

  const hours = backendDate.getHours().toString().padStart(2, "0");
  const minutes = backendDate.getMinutes().toString().padStart(2, "0");

  if (
    backendDate.getDate() === currentDate.getDate() &&
    backendDate.getMonth() === currentDate.getMonth() &&
    backendDate.getFullYear() === currentDate.getFullYear()
  ) {
    return `${hours}:${minutes}`;
  } else if (
    currentDate - backendDate <= oneDayInMilliseconds &&
    currentDate - backendDate > oneWeekInMilliseconds
  ) {
    return "Вчора";
  } else if (currentDate - backendDate <= oneWeekInMilliseconds) {
    const dayOfWeek = daysOfWeek[backendDate.getDay()];
    return `${dayOfWeek} ${hours}:${minutes}`;
  } else if (backendDate.getFullYear() === currentDate.getFullYear()) {
    const monthName = months[backendDate.getMonth()];
    const dayOfMonth = backendDate.getDate();
    return `${dayOfMonth} ${monthName}`;
  } else {
    const dayOfMonth = backendDate.getDate();
    const monthName = months[backendDate.getMonth()];
    return `${dayOfMonth} ${monthName} ${backendDate.getFullYear()}`;
  }
};
