import { useEffect, useState } from "react";
import { getStatistics } from "../http/managerAPI";
import SexCount from "../components/stats/SexCount";
import HowFindUs from "../components/stats/HowFindUs";
import AverageAge from "../components/stats/AverageAge";
import AverageRate from "../components/stats/AverageRate";
import UsersCount from "../components/stats/UsersCount";
import AvarageCountByTimeCategories from "../components/stats/AvarageCountByTimeCategories";
import AvaragePeriodByAgeCategories from "../components/stats/AvaragePeriodByAgeCategories";

import ActivityTime from "../components/stats/ActivityTime";
import Question from "../components/stats/Question";
import styles from "./styles/Statistics.module.css";
import { toast } from "react-toastify";
import { useAuth } from "../AuthContext";
import LoaderComponent from "../components/LoaderComponent";

const Statictics = ({ socket, setIsPlay, setIsMessageSoundPlay }) => {
  const { chatId } = useAuth();
  const [stats, setStats] = useState([]);
  const [isLoad, setisLoad] = useState(true);
  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const statistics = await getStatistics();
        setStats(statistics);
      } catch (error) {
      } finally {
        setisLoad(false);
      }
    };
    fetchStatistics();
  }, []);

  const handleNotificationClick = () => {
    setIsPlay(0);
  };

  useEffect(() => {
    socket.on("message", async (newMessage) => {
      const storedChatIds = JSON.parse(localStorage.getItem("chatIds")) || [];
      if (
        storedChatIds.includes(newMessage.channelId) &&
        newMessage.type === "text" &&
        chatId !== newMessage.user.id
      ) {
        const truncatedText =
          newMessage.text.length > 50
            ? `${newMessage.text.slice(0, 50)}...`
            : newMessage.text;
        toast.info(
          `Нове повідомлення\n${newMessage.user.firstName}:\n ${truncatedText}`
        );
        setIsMessageSoundPlay((prev) => (prev += 1));
      }
    });
    socket.on("newConsultation", () => {
      toast.info("Зявився новий запит", {
        onClick: handleNotificationClick,
        autoClose: 9000,
      });
      setIsPlay((prev) => (prev += 1));
    });
    return () => {
      socket.off("newConsultation");
      socket.off("message");
    };
  }, [socket]);

  if (isLoad) {
    return <LoaderComponent />;
  }

  if (!isLoad)
    return (
      <>
        <section className={styles.containerForThreeRows}>
          <div className={styles.userCount}>
            {stats && `Консультацій проведено: ${stats.endConsultationsCount}`}
          </div>
          <div className={styles.userCount}>
            {stats &&
              `Консультацій в обробці: ${stats.inProgressConsultationsCount}`}
          </div>
          <div className={styles.userCount}>
            {stats &&
              `Консультацій у черзі: ${stats.pendingConsultationsCount}`}
          </div>

          <div className={styles.userCount}>
            {stats && `Всього користувачів: ${stats.allUsersCount}`}
          </div>
          <div className={styles.userCount}>
            {stats &&
              `Всього унікальних користувачів: ${stats.allUnicalConsultationsCount}`}
          </div>
        </section>
        <section className={styles.container}>
          <div className={styles.userCount}>
            {stats && <UsersCount data={stats} />}
          </div>
          <div className={styles.userCount}>
            {stats && <SexCount data={stats} />}
          </div>
          <div className={styles.userCount}>
            {stats && <HowFindUs data={stats} />}
          </div>
          <div className={styles.userCount}>
            {stats && <AverageAge data={stats} />}
          </div>
          <div className={styles.userCount}>
            {stats && <ActivityTime data={stats} />}
          </div>
          <div className={styles.userCount}>
            {stats && <AverageRate data={stats} />}
          </div>
          <div className={styles.userCount}>
            {stats && <AvaragePeriodByAgeCategories data={stats} />}
          </div>
          <div className={styles.userCount}>
            {stats && <AvarageCountByTimeCategories data={stats} />}
          </div>
          {stats &&
            stats.questions.map((data, index) => (
              <div key={index} className={styles.userCount}>
                <Question data={data} months={stats.months} />
              </div>
            ))}
        </section>
      </>
    );
};

export default Statictics;
