import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const ActivityTime = ({ data }) => {
  function secondsToHHMM(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    const formattedTime = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}`;

    return formattedTime;
  }

  const timeData = data.activityTime.map(secondsToHHMM);

  const timeValues = timeData.map((time) => {
    const [hours, minutes] = time.split(":");
    return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
  });

  const data1 = {
    labels: data.months,
    datasets: [
      {
        label: "Час",
        data: timeValues,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,

        ticks: {
          callback: function (value) {
            const hours = Math.floor(value / 60);
            const minutes = value % 60;
            return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },

      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.parsed.y;
            const hours = Math.floor(value / 60);
            const minutes = value % 60;
            return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
          },
        },
      },
    },
  };

  return (
    <div>
      <h2>Піковий час доби</h2>
      <Line data={data1} options={options} />
    </div>
  );
};

export default ActivityTime;
