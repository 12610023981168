import axios from "axios";
import { URL } from "../utils/consts";

const $host = axios.create({
  baseURL: `${URL}`,
});

const $authHost = axios.create({
  baseURL: `${URL}`,
});

const authInterceptor = (config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
};

$authHost.interceptors.request.use(authInterceptor);

export { $host, $authHost };
