import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "./AuthContext";
import Sound from "./components/Sound";

import "./App.css";
import AppRouter from "./components/AppRouter";
import NavBar from "./components/NavBar";
import { useEffect, useState } from "react";
import { refresh } from "./http/managerAPI";

import { URL } from "./utils/consts";
import { io } from "socket.io-client";

const socket = io(`${URL}`);

const App = () => {
  const { setChatId, setIsAdmin, setIsAuth, isAuth, out } = useAuth();
  const [loading, setLoading] = useState(true);
  const [isPlay, setIsPlay] = useState(0);
  const [isMessageSoundPlay, setIsMessageSoundPlay] = useState(0);
  const [showSoundModal, setShowSoundModal] = useState(true);

  useEffect(() => {
    const asd = async () => {
      try {
        const { data } = await refresh();
        if (data) {
          setIsAdmin(data.isAdmin);
          setIsAuth(true);
          setChatId(data.chatUser.id);
        } else {
          out();
        }
      } catch (error) {
        out();
      } finally {
        setLoading(false);
      }
    };
    asd();
    // eslint-disable-next-line
  }, []);

  if (!loading) {
    return (
      <div className="app">
        <BrowserRouter>
          {isAuth ? <NavBar /> : <></>}
          <AppRouter
            socket={socket}
            setIsPlay={setIsPlay}
            setIsMessageSoundPlay={setIsMessageSoundPlay}
          />
        </BrowserRouter>
        <Sound
          isPlay={isPlay}
          isMessageSoundPlay={isMessageSoundPlay}
          showSoundModal={showSoundModal}
          setShowSoundModal={setShowSoundModal}
        ></Sound>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
          theme="dark"
          closeButton={false}
        />
      </div>
    );
  }
};

export default App;
